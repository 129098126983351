import { render, staticRenderFns } from "./ViewShikara.vue?vue&type=template&id=610f12fa&scoped=true"
import script from "./ViewShikara.vue?vue&type=script&lang=js"
export * from "./ViewShikara.vue?vue&type=script&lang=js"
import style0 from "./ViewShikara.vue?vue&type=style&index=0&id=610f12fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "610f12fa",
  null
  
)

export default component.exports